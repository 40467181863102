@import '../../base';

.form-control {
  height: 40px;
  font-size: $h7-font-size;
  padding: $spacer;
  box-sizing: border-box;
  color: $gray-title;
  border-color: $gray-subtext-accessible;

  &:focus {
    color: $gray-title;
  }

  &:disabled {
    background-color: $gray-background;
    color: $gray-body;
    border: 1px solid $gray-subtext;
  }

  &:active {
    border: 2px solid $sure-sage-700;
  }
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid $black;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $gray-subtext-accessible;
  }
}

.form-control-sm {
  height: 30px;
  font-size: $body-small;
}

.form-control-md {
  height: 40px;
  font-size: $h7-font-size;
}

.form-control[readonly] {
  background-color: $input-bg;
}
