@import '../../base';

// Standard table (bootstrap)
// ------------------------------------------

.table {
  border-radius: $border-radius;

  th,
  td,
  thead th {
    height: map-get($spacers, 7);
    vertical-align: middle;
    font-weight: normal;
  }

  .table-details,
  .b-table-details {
    background: transparent !important;

    @extend %table-expandable-row;
  }
}

.table-bordered {
  // No vertical borders
  th,
  td {
    border-right: 0;
    border-left: 0;
    border: 0;
    border-bottom: none;
  }

  // Reset colored borders that bootstrap gives to contextual rows (e.g. success, danger)
  th,
  td,
  thead th,
  tbody + tbody {
    border-color: $gray-border;
  }

  thead th {
    color: $table-head-color;
  }
}

// Ingenico table (wraps bootstrap table)
// ------------------------------------------

.ig-table {
  &.with-shadow {
    box-shadow: $shadow-container;
  }
  border-radius: $border-radius;

  &-header,
  &-header-secondary,
  &-header-tertiary,
  &-footer {
    background: $table-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &,
    &-cell {
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }

    &-name {
      font-size: $h3-font-size;
      line-height: $h3-line-height;
      color: $table-head-color;
    }
  }

  &-header {
    min-height: map-get($spacers, 8);
  }

  &-header-secondary,
  &-header-tertiary,
  &-footer {
    min-height: map-get($spacers, 7);
  }

  &-header-secondary {
    background-color: $gray-background;
    border-left: $table-border-width solid $table-border-color;
    border-right: $table-border-width solid $table-border-color;
    padding-top: map-get($spacers, 1);
    padding-bottom: map-get($spacers, 1);
  }
  &-header-tertiary {
    background-color: $white;
    border-left: $table-border-width solid $table-border-color;
    border-right: $table-border-width solid $table-border-color;
  }

  &-header {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    border: $table-border-width solid $table-border-color;
    border-bottom: 0;
  }
  &-footer {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border: $table-border-width solid $table-border-color;
    border-top: 0;
  }

  &-body {
    overflow-x: auto;
    border-left: $table-border-width solid $table-border-color;
    border-right: $table-border-width solid $table-border-color;
  }

  .table {
    margin: 0;
    border-left: 0;
    border-right: 0;
  }

  .ig-filter {
    width: 500px;
    form {
      padding: 0.25rem 1.5rem;
      .reset-txt {
        bottom: 2px;
        left: 2px;
      }
    }
  }

  // Bootstrap dropdown customized for Ingenico Table
}

// Utility classes for table content
// ------------------------------------------

// Show content only on row hover (apply to content in individual table cells):
.on-row-hover {
  opacity: 0;
  transition: opacity 0.2s;
  .table-hover tbody tr:hover & {
    opacity: 1;
  }
}
