$default: #c5c6d1;
@mixin card-layered-variant($bgcolor: $default) {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-border;
  border-radius: $border-radius;
  min-width: 280px;
  background-color: $bgcolor;
  & .card-layered-title {
    background-color: rgba($white, 0.5);
    font-weight: bold;
    padding: 0.5rem;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  & .card-layered-body {
    @for $i from 1 through 4 {
      & .slice-#{$i} {
        padding: 0.25rem 0.5rem;
      }
    }
    & .slice-1 {
      background-color: rgba($white, 0.7);
    }
    & .slice-2 {
      background-color: rgba($white, 0.8);
    }
    & .slice-3 {
      background-color: rgba($white, 0.9);
    }
    & .slice-4 {
      background-color: rgba($white, 0.9);
    }
    & div:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  &.active {
    box-shadow: $shadow-modal-dropdown;
    border: 1px solid $bgcolor;
    & .card-layered-title {
      background-color: rgba($white, 0.1);
    }
    &:after {
      content: '';
      border-color: rgba($white, 0.6) transparent transparent;
      border-style: solid;
      border-width: 15px;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -30px;
      left: calc(50% - 15px);
    }
    &:before {
      content: '';
      border-color: $bgcolor transparent transparent;
      border-style: solid;
      border-width: 16px;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -32px;
      left: calc(50% - 16px);
    }
    & .card-layered-body {
      & .slice-1 {
        background-color: rgba($white, 0.3);
      }
      & .slice-2 {
        background-color: rgba($white, 0.5);
      }
      & .slice-3 {
        background-color: rgba($white, 0.6);
      }
      & .slice-4 {
        background-color: rgba($white, 0.6);
      }
      & div:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

@mixin custom-card-layered-varient($bgcolor: $default) {
  background-color: $bgcolor;
  &.active {
    border: 1px solid $bgcolor;
    &:before {
      border-color: $bgcolor transparent transparent;
    }
  }
}

.card {
  &.card-layered {
    @include card-layered-variant();
  }
}
