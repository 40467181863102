@import "../../base";

.toast {
  display: flex !important;
  flex-direction: row;
  align-items: stretch;
  padding: 1rem;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  .toast-icon {
    display: inline-block;
    flex-basis: 1.5rem;
    text-align: center;
  }
  .toast-content {
    flex-grow: 10;
    padding: 0 1rem;
    .toast-header {
      border-bottom: none;
      font-size: 0.9375rem;
      line-height: 1.6;
      
    }
    .toast-body {
      font-size: 0.9375rem;
      color: $gray-body;
    }
  }
  .toast-x {
    flex-basis: 1rem;
    text-align: center;
    .close {
      float: initial !important;
    }
  }
}


