@import '../foundation/border-radius';

@import './inputs-and-buttons';

// Buttons
// ======================================================================

$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius: $border-radius-sm;
$btn-border-radius-lg: $border-radius-sm;

//TODO: set dropdown styles

@mixin custom-button-variant($options) {
  $background: map-get($options, 'background');
  $color: map-get($options, 'color');
  $border: map-get($options, 'border');
  $hover-background: map-get($options, 'hover-background');
  $hover-color: map-get($options, 'hover-color');
  $hover-border: map-get($options, 'hover-border');
  $active-background: map-get($options, 'active-background');
  $active-color: map-get($options, 'active-color');
  $active-border: map-get($options, 'active-border');
  $focus-background: map-get($options, 'focus-background');
  $focus-color: map-get($options, 'focus-color');
  $focus-border: map-get($options, 'focus-border');
  $disabled-background: map-get($options, 'disabled-background');
  $disabled-color: map-get($options, 'disabled-color');
  $disabled-border: map-get($options, 'disabled-border');
  $text-decoration-color: map-get($options, 'hover-text-color');

  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover,
  &.hover,
  &:focus-visible,
  &.focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    text-decoration: underline;
    text-decoration-color: $text-decoration-color;
  }

  &:focus-visible,
  &.focus {
    color: $focus-color;
    background-color: $focus-background;
    text-decoration: none;
    border-color: $focus-border;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    border-color: $disabled-border;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
      }
    }
  }
}
