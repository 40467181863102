@import '../foundation/border-radius';
@import '../foundation/color';

@import './inputs-and-buttons';

$label-margin-bottom: 0.25rem;

$input-border-radius: $border-radius-sm;
$input-border-radius-lg: $border-radius-sm;
$input-border-radius-sm: $border-radius-sm;

$input-focus-border-color: $master-mint-700;

$input-group-addon-bg: #fff;
