@import "../foundation/spacing-scale";
@import "../foundation/type-scale";

@mixin custom-badge-variant($bg, $color, $border, $hover-bg: darken($bg, 3%)) {
  color: $color;
  background-color: $bg;
  border: 1px solid $border;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: normal;
  padding: 4px map-get($spacers, 1) 3px;

  @at-root a#{&} {
    &:hover, &:focus {
      color: $color;
      background-color: $hover-bg;
    }
  }
}
