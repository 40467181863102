// Color Palette
// ======================================================================

// ======================
// Primary Colors
// ======================
$sure-sage-100: #e5f2f4;
$sure-sage-200: #84d7d7;
$sure-sage-300: #4fc6c6;
$sure-sage-400: #38aeae;
$sure-sage-500-opacity-30: #00a6a4;
$sure-sage-500: #2d8c8c;
$sure-sage-600: #2b8182;
$sure-sage-700: #236d6d;
$sure-sage-800: #1d5959;
$sure-sage-900: #164545;

// ======================
// Secondary Colors
// ======================
$master-mint-100: #e5fafa;
$master-mint-200: #aaf7d8;
$master-mint-300: #7ce8c5;
$master-mint-400: #58d2b5;
$master-mint-500: #45beaa;
$master-mint-500-opacity-30: #c7ebe5;
$master-mint-500-opacity-50: #89ccc7;
$master-mint-600: #149990;
$master-mint-700: #007b7c;
$master-mint-800: #005962;
$master-mint-900: #004150;
$master-mint-regular: #46beaa;

// ======================
// Sementic Colors
// ======================
$success-900: #093421;
$success-800: #0f3f24;
$success-700: #134f29;
$success-600: #1c5e2e;
$success-500: #008556;
$success-400: #56a85c;
$success-300: #84d380;
$success-200: #b9efb1;
$success-100: #eef7f4;

$warning-900: #790d0f;
$warning-800: #921c16;
$warning-700: #b73118;
$warning-600: #db4c22;
$warning-500: #fe6c30;
$warning-accessible-500: #f85712;
$warning-400: #ff9b63;
$warning-300: #feb781;
$warning-200: #ffd5ab;
$warning-100: #feecd4;
$warning-100-opacity-30: #fef9f2;

$error-900: #660933;
$error-800: #7c0c36;
$error-700: #9b0c37;
$error-600: #b81333;
$error-500: #d81b2f;
$error-400: #e84f51;
$error-300: #f37d73;
$error-200: #fbb2a3;
$error-100: #ffeae4;
$error-100-opacity-30: #fff2ef;

$info-900: #00193a;
$info-800: #002246;
$info-700: #002e58;
$info-600: #003d6b;
$info-500: #005a8c;
$info-400: #1585b0;
$info-300: #3fb5d5;
$info-300-opacity-85: #3fb5d5;
$info-200: #7edef0;
$info-100: #e9fffd;
$info-100-opacity-30: #eef8fb;

// ======================
// Brand Colors
// ======================
$bold-blueberry-100: #d0fbf8;
$bold-blueberry-200: #a4f9f6;
$bold-blueberry-300: #76e8ee;
$bold-blueberry-400: #54cede;
$bold-blueberry-500: #41b4d2;
$bold-blueberry-600: #1784a8;
$bold-blueberry-700: #0e628a;
$bold-blueberry-800: #06456c;
$bold-blueberry-900: #033258;

$friendly-fig-100: #7ad0ff;
$friendly-fig-200: #45beff;
$friendly-fig-300: #009ff4;
$friendly-fig-400: #007cbf;
$friendly-fig-500: #005a8c;
$friendly-fig-600: #004d76;
$friendly-fig-700: #00466c;
$friendly-fig-800: #003a59;
$friendly-fig-900: #00334f;

$reliable-rhubarb-100: #fce6e8;
$reliable-rhubarb-200: #fad5d9;
$reliable-rhubarb-300: #f8c4c9;
$reliable-rhubarb-400: #f6b3ba;
$reliable-rhubarb-500: #f08791;
$reliable-rhubarb-600: #cf576c;
$reliable-rhubarb-700: #a93c57;
$reliable-rhubarb-800: #842745;
$reliable-rhubarb-900: #6c183b;
$reliable-rhubarb-lightest: #dc747f;

$confident-corn-100: #fffbe0;
$confident-corn-200: #fff8c4;
$confident-corn-300: #fff3a8;
$confident-corn-400: #ffee93;
$confident-corn-500: #ffeb78;
$confident-corn-600: #ffdb0f;
$confident-corn-700: #d8b700;
$confident-corn-800: #a28900;
$confident-corn-900: #877300;

// ======================
// Neutral Colors
// ======================
$black: #000000;
$black-opacity-40: #404040;
$gray-title: #141317;
$gray-body: #575665;
$gray-subtext-accessible: #74748b;
$gray-subtext: #8b8b9a;
$gray-highlight: #bfbdc8;
$gray-input: #c8c8cb;
$gray-border: #e2e1e6;
$gray-light: #ebebf1;
$gray-background: #f5f6fa;
$gray-row-background: #fafafa;
$gray-branding-bg: #efefef;
$white: #ffffff;

// ======================
// Overlay Colors
// ======================
$gray-accessible: #6a6c6c;
$gray-decorative-1: #616363;
$gray-decorative-2: #555858;
$gray-decorative-3: #4a4c4c;
$gray-decorative-4: #3f4141;
$gray-decorative-5: #353838;
$gray-decorative-6: #2c2f2f;
$gray-decorative-7: #1a1d1d;

// ======================
// Gray text Colors
// ======================
$text-gloun-gray: #1b1c1e;
$text-accessible-gray: #7b8a93;
$text-dark-gray: #909d9b;
$text-medium-gray: #aebbb9;
$text-light-gray: #e7e9e9;
$text-white: #ffffff;