@import '../../base';

.badge {
  border-radius: $border-radius-sm;
}

.badge-success {
  @include custom-badge-variant(#fff, $success, $success);
}
.badge-warning {
  @include custom-badge-variant(#fff, $warning, $warning);
}
.badge-failure {
  @include custom-badge-variant(#fff, $error-500, $error-500);
}
.badge-light {
  @include custom-badge-variant(#fff, $gray-subtext, $gray-subtext);
}
.badge-dark {
  @include custom-badge-variant(#fff, $gray-title, $gray-title);
}
.badge-gray-body {
  @include custom-badge-variant(#fff, $gray-body, $gray-body);
}
.badge-tag {
  @include custom-badge-variant(#fcfcfc, $info-500, $info-500);
}
