@import "../../base";

@each $shadow, $_ in $shadows {
  .shadow-#{$shadow} {
    @if $shadow == "hover" {
      & { transition: all .2s ease-in-out; }
      &:hover { box-shadow: $_; }
    } @else {
      & { box-shadow: $_; }
    }
  }
}
