@import '../../base';

// .page-layout {
//   position: absolute;
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
// }
body,
h1,
p,
header,
aside,
main {
  margin: 0;
  padding: 0;
}

.page-header {
  background: $white;
  color: $master-mint-700;
  z-index: 1;
  position: relative;
}

.col-page-title {
  font-size: $font-body-lg;
  line-height: $body-1-line-height;
}

.page-main > .container > .row > .col {
  padding-top: map-get($spacers, 4);
  padding-bottom: map-get($spacers, 3);
}

.page-container,
.page-footer {
  background: #fff;
  box-shadow: $shadow-container;
  border: $border-width solid $gray-border;
  border-radius: $border-radius;
  padding: map-get($spacers, 2) map-get($spacers, 5);
}

.page-footer {
  border-radius: $border-radius;
  margin-top: map-get($spacers, 5);
}
.page-container-header {
  background: $master-mint-700;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  margin: (map-get($spacers, 2) * -1) (map-get($spacers, 5) * -1) map-get($spacers, 2);
  padding: map-get($spacers, 2) map-get($spacers, 5);

  color: #fff;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

.page-container-narrow {
  @extend .page-container;
  padding: map-get($spacers, 2) map-get($spacers, 2);
}

.page-container-header-narrow {
  @extend .page-container-header;
  margin: (map-get($spacers, 2) * -1) (map-get($spacers, 2) * -1) map-get($spacers, 2);
  padding: map-get($spacers, 2) map-get($spacers, 2);
}

// On medium and up, make side nav fixed and give it its own scroll bar
// @include media-breakpoint-up(md) {
//   .page-main {
//     position: absolute;
//     left: 0;
//     top: $page-header-height;
//     right: 0;
//     bottom: 0;
//   }

//   .col-side-nav,
//   .page-main {
//     overflow-y: auto;
//   }

//   .col-side-nav {
//     height: calc(100vh - #{$page-header-height});
//     position: relative;
//     z-index: 1;
//   }
// }

// Grid column sizing & alignment
.page-header > .container > .row {
  align-items: center;
  height: $page-header-height;
}
.col-page-title {
  @include make-col(3);
  @include media-breakpoint-up(xl) {
    @include make-col(2);
  }
}
.col-user {
  flex-grow: 0;
  white-space: nowrap;

  .btn + .btn {
    margin-left: map-get($spacers, 1);
  }
}
.col-side-nav {
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(2);
  }
  &.col-collapse {
    text-align: center;
    overflow-x: hidden;
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(0.5);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(0.5);
    }
  }
  // transition: all 0.5s ease-in-out;
  // -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.col-main {
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col-offset(3);
    @include make-col(9);
  }
  @include media-breakpoint-up(xl) {
    @include make-col-offset(2);
    @include make-col(10);
  }
  &.col-collapse {
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col-offset(0.5);
      @include make-col(11.5);
    }
    @include media-breakpoint-up(xl) {
      @include make-col-offset(0.5);
      @include make-col(11.5);
    }
  }
  // transition: all 0.5s ease-in-out;
  // -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
// Grid gutter overrides for page-layout elements
.col-page-title,
.col-search,
.col-user {
  padding-right: 20px;
  padding-left: 20px;
}

.col-main {
  padding-right: 16px;
  padding-left: 16px;
}

// container without added paddings
.container-zero-pad {
  @include make-container(0);
}

/* Base styles for the entire layout */
.app-fixed-header {
  grid-area: header;
}

.sidebar-container {
  grid-area: sidebar;
}

.content-container {
  grid-area: content-container;
}

.wrapper {
  display: grid;
  grid-template-areas: 'header header' 'sidebar content-container';
  grid-template-columns: 332px 1fr; /* Sidebar and content container */
  grid-template-rows: 72px 1fr; /* Fixed header and the rest of the content */
  height: 100vh; /* Ensure the grid covers the full viewport height */
  overflow: hidden;

  &.col-collapse {
    grid-template-columns: min-content 1fr;
  }
}

/* Fixed header styles */
.app-fixed-header {
  grid-column: 1 / span 2; /* Make the header span across both columns */
  background: $app__header--background;
  color: $master-mint-700;
  height: 72px;
}

@keyframes slide-in {
  from {
    offset: 0;
  }

  to {
    offset: 1;
  }
}

/* Collapsible sidebar styles */
.sidebar-container {
  grid-row: 2 / span 1; /* Start at row 2 and span 1 row */
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0 0.75rem 0.75rem;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;

  &.col-collapse {
    animation-name: slide-in;
    animation-duration: 5s;
  }
}

/* Content container styles */
.content-container {
  height: calc(100vh - 72px);
  grid-row: 2 / span 1; /* Start at row 2 and span 1 row */
  grid-column: 2 / span 1; /* Start at column 2 and span 1 column */
  background-color: $app__content--background;
  padding: 0 map-get($spacers, 4) map-get($spacers, 4) map-get($spacers, 3);
  overflow: auto;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  /* Media query for large screens */
  .wrapper {
    grid-template-columns: 206px 1fr; /* Sidebar and content container */
  }

  .sidebar-container {
    padding: map-get($spacers, 2) + map-get($spacers, 1) 0;
  }

  .content-container {
    grid-row: 2 / span 1;
  }
}

@media screen and (max-width: map-get($container-max-widths, md)) {
  /* Media query for medium screens */
  .wrapper {
    grid-template-columns: 189px 1fr; /* Sidebar and content container */
    grid-template-rows: 56px 1fr; /* Fixed header and the rest of the content */
  }

  .app-fixed-header {
    height: 56px;
  }

  .sidebar-container {
    display: none;

    .side-menu-footer {
      display: none;
    }

    &.active {
      display: flex;
      z-index: 2;
      background-color: white;
      margin-left: 12px;
      width: max-content;
      height: max-content;
    }
  }

  .content-container {
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
  }
}

@media screen and (max-width: map-get($container-max-widths, sm)) {
  .wrapper {
    grid-template-columns: 1fr; /* Sidebar and content container */
  }
}
