$spacer: 8px;

$spacers: (
  0: 0,
  1: ( $spacer * 0.5 ),
  2: $spacer,
  3: ( $spacer * 2 ),
  4: ( $spacer * 3 ),
  5: ( $spacer * 4 ),
  6: ( $spacer * 5 ),
  7: ( $spacer * 6 ),
  8: ( $spacer * 7 ),
  9: ( $spacer * 8 ),
  10: ( $spacer * 9 ),
);
