$shadow-background-dropdown: 0 2px 8px rgba(20, 19, 23, 0.08);
$shadow-hover: 0 4px 8px rgba(20, 19, 23, 0.08);
$shadow-modal-dropdown: 0 4px 24px rgba(20, 19, 23, 0.16);
$shadow-modal-dark-bg: 0 4px 32px rgba(20, 19, 23, 0.24);
$shadow-container: 0 3px 14px 0 #e8eaef;

$shadows: (
  "hover": $shadow-hover,
  "background-dropdown": $shadow-background-dropdown,
  "modal-dropdown": $shadow-modal-dropdown,
  "modal-dark-bg": $shadow-modal-dark-bg,
  "container": $shadow-container
);
