@import '../src/lib/base/foundation/color';
@import 'type-scale';

// gray
$gray-hover: $gray-light;
$gray-box-shadow: 0 4px 4px 0 rgba(0 0 0 / 25%);

$primary: $master-mint-700;

$success-bg: #51a551;
$failure-bg: #ea5c59;
$warning-bg: #ffbe00;
$gray-overlay: #eeeeee;
$gray-subtext: $gray-subtext;

// component specific
$search--border-shadow: $black-opacity-40;
$app__header--background: $white;
$app__header--color: $black;

$app__content--background: $white;

$table--border-light: $gray-border;
$table--background-light: $gray-row-background;

$icon__hollow--base-color: $master-mint-700;
$icon__hollow--highlight-color: $master-mint-500;
$icon__fill--color: $primary;
$icon-fill--gray: $gray-subtext;
$icon__border--color: $gray-subtext-accessible;
$icon__fill--color--white: $white;
$icon__fill--color--black: $black;
$icon__background-color--white: $white;
$icon__background-color--surface-brand-extra-light: $master-mint-500-opacity-30;

$text--highlight-color: $white;
$text-white: $white;
$text-green: $success-500;
$text-red: $error-500;
$text-yellow: $warning-500;
$text-blue: $master-mint-500;
$text-gray: $gray-body;
$text-gray-2: $gray-subtext;
$text-background-green: $success-100;
$text-background-yellow: $warning-100;
$text-background-red: $error-100;
$text-background-blue: $info-100;
$gray-title: $gray-title;
$label-gray: $gray-body;

$sidebar-active--bg-color: $gray-border;

$row-background--gray: $gray-row-background;
$background-light-gray: $gray-background;

$border-gray: $gray-border;
$border-brand-dark: $sure-sage-900;
$border-decorative-primary: $gray-subtext;

$spinner-overlay: $gray-overlay;

$table-cell-padding: 0.75rem;

$table-cell-height: 53px;

$small-font-size: $body-small;
$label-color-primary: $sure-sage-700;
$icon__background-color--surface-brand-extra-light: $master-mint-500-opacity-30;
