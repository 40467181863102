@import './color.scss';
@import '../functions/url-encode';

$icon-size-xs: 8px;
$icon-size-sm: 16px;
$icon-size-md: 24px;
$icon-size-lg: 32px;

$icon-sizes: (
  'xs': $icon-size-xs,
  'sm': $icon-size-sm,
  'md': $icon-size-md,
  'lg': $icon-size-lg,
);

$arrow-directions: (
  'bottom': rotate(90deg),
  'left': rotate(90deg * 2),
  'top': rotate(90deg * 3),
);

@mixin arrow-direction($direction) {
  transform: #{map-get($arrow-directions, $direction)};
}

@mixin icon-size($size) {
  $size: map-get($icon-sizes, $size);
  width: $size;
  height: $size;
  background-size: $size $size;
}

@mixin icon($name, $color: $gray-body, $path: '/assets/icons') {
  background-color: $color;
  -webkit-mask-image: url('#{$path}/#{$name}.svg');
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;

  mask-image: url('#{$path}/#{$name}.svg');
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}
