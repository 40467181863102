@import '../foundation/borders';
@import '../foundation/color';
@import '../foundation/spacing-scale';
@import '../foundation/type-scale';

// List navigation

$nav-link-padding-y: 0.25rem;
$nav-link-padding-x: map-get($spacers, 1);

$nav-link-color: $master-mint-800;

$nav-link-active-color: $master-mint-800;
$nav-link-active-background: $gray-light;

$nav-link-hover-color: $master-mint-700;
$nav-link-disabled-color: $gray-highlight;

// Tabs (extends list navigation)

$nav-tabs-border-width: $border-width-thick;
$nav-tabs-border-radius: 0;

$nav-tabs-link-padding-y: 0.125rem;
$nav-tabs-link-padding-x: map-get($spacers, 1);

$nav-tabs-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;

$nav-tabs-link-active-color: $gray-title;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent transparent $master-mint-700;

$nav-tabs-link-color-on-dark-background: transparentize(#fff, 0.2);
$nav-tabs-link-active-color-on-dark-background: #fff;
$nav-tabs-link-hover-color-on-dark-background: #fff;
$nav-tabs-link-disabled-color-on-dark-background: transparentize(#fff, 0.7);
$nav-tabs-link-active-border-color-on-dark-background: transparent transparent #fff;

// Breadcrumb

$breadcrumb-bg: transparent;
$breadcrumb-item-padding: 0.25rem;
$breadcrumb-divider: quote('>');
