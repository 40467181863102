@import 'variables';
@import 'icons';
@import 'type-scale';

$active-row-bg: #fffeec;

.table {
  .th,
  .td {
    padding: $table-cell-padding;
    vertical-align: top;
  }

  .thead {
    .table-header {
      height: 4rem;
    }

    .top-pagination {
      height: 3.5rem;
      color: $gray-subtext;
      background-color: $gray-background;
    }

    .th {
      border: none;
      font-size: $small-font-size;
      font-weight: $font-regular;
    }
  }
}

.ngx-datatable.material {
  box-shadow: none;
  background-color: transparent;

  .datatable-header {
    border-bottom: 2px solid $primary;

    .datatable-header-cell {
      font-size: $small-font-size;
      font-weight: $font-bold;
      color: $label-gray;
      height: 56px;

      .datatable-header-cell-label {
        /* disable user selection inside data-table header cell */
        user-select: none;
      }
    }
  }

  .datatable-body {
    .datatable-body-row {
      &.datatable-row-even {
        .datatable-row-group {
          background-color: $row-background--gray;
        }
      }

      &.active {
        .datatable-row-group {
          background-color: $active-row-bg;
        }
      }

      .datatable-body-cell {
        .datatable-body-cell-label {
          span {
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row:hover {
      .datatable-row-group {
        background-color: $gray-background;
      }
    }
  }

  .datatable-footer {
    .selected-count {
      .page-count {
        flex: 1 1 30%;
      }

      .page-size {
        flex: 1 1 30%;

        .filter-input-group {
          width: 185px;

          .form-control,
          .filter-input-group-label {
            height: 35px;
          }
        }
      }

      .datatable-pager {
        flex: 2 0.6 80px;
        margin: 0 8px 0 0;

        li {
          //outline: 1px solid red;
          &.disabled a {
            opacity: 0.26 !important;
          }
        }

        a {
          padding: 0;
          border-radius: 50%;
        }
      }
    }
  }

  .icon-chevron-right-master-mint-700,
  .icon-last-page-master-mint-700 {
    height: 22px;
    width: 22px;
    background-position: center 6px;
    background-repeat: no-repeat;
    background-size: 8px;
    border-radius: 50%;
    border: 1px solid $gray-border;
  }

  &.material .datatable-header .datatable-row-left {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAYAAACnOeyiAAAAAXNSR0IArs4c6QAAABhJREFUCB1j/Pzp6//fv38zMDFAweBnAAC5sAbymP181wAAAABJRU5ErkJggg==);
  }

  &.material .datatable-body .datatable-row-left {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAUCAYAAACnOeyiAAAAAXNSR0IArs4c6QAAABhJREFUCB1j/Pzp6//fv38zMDFAweBnAAC5sAbymP181wAAAABJRU5ErkJggg==);
  }
}

.datatable-checkbox {
  input[type='checkbox'] {
    &:before {
      border: 2px solid $gray-border;
    }

    &:checked:before {
      border-color: $primary;
    }
  }
}

.icon-last-page-master-mint-700 {
  @include icon('last-page', $primary);
}
