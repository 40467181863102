$container-max-widths: (
  xs: 599px,
  sm: 1023px,
  md: 1279px,
  lg: 1919px,
  xl: 2560px,
);

$grid-gutter-width: 16px;

$page-header-height: 72px;
$page-header-height-sm: 56px;
$page-header-height-md: 72px;

@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}
