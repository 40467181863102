@use 'sass:math';

@import '../../base';

.search-bar {
  position: relative;
}

.search-icon {
  position: absolute;
  right: ($spacer * 2);
  top: $spacer;
  pointer-events: none;
  // @include icon(search);
  @include icon-size(sm);
}

.search-input {
  border-color: transparent;
  border-radius: $border-radius-100;
  padding-right: ($spacer * 9);
}

.search-input-clear-btn {
  position: relative;
  top: 1px;

  &-icon {
    // @include icon(close);
    @include icon-size(xs);
  }

  &-container {
    position: absolute;
    right: ($spacer * 5);
    padding-right: math.div($spacer, 3);
    top: 3px;
    bottom: 3px;
    border-right: $border-width solid $gray-border;
  }
}

.search-bar-expansion {
  &-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  &-form {
    position: absolute;
    left: 0;
    right: 0;
    top: ($spacer * 5);
    padding: $spacer;
    background: #fff;
    color: $gray-body;
    border-radius: $border-radius;
    border: $border-width solid $gray-border;
    box-shadow: $shadow-background-dropdown;
    z-index: 101;
  }

  &-content {
    padding: $spacer;
  }

  &-buttons {
    padding: 0 $spacer $spacer;
  }
}
