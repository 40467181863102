@import '../foundation/color';
@import '../foundation/spacing-scale';
@import '../functions/map-deep-get';

$table-cell-padding: map-get($spacers, 2);
//$table-cell-padding-sm:       .3rem;

$table-color: $gray-body;
$table-bg: #fff;
$table-hover-color: $table-color;
$table-hover-bg: $gray-background;
$table-active-bg: #fffeec;

//$table-border-width:          $border-width;
$table-border-color: $gray-border;

$table-head-bg: $gray-background;
$table-head-color: $gray-title;

$table-striped-order: odd;

$table-bg-level: -12;
$table-border-level: -6;

@mixin prevent-double-border($delay) {
  border-color: transparent;
  transition: border-color 0s $delay;
}

// Behaviors
%table {
  &-expandable-row {
    $transition: map-deep-get($transitions, slide, duration) map-deep-get($transitions, slide, easing);

    border-top: 1px double $gray-highlight;
    transition: border-top-color $transition;

    td {
      height: auto;
      padding: 0;
    }

    td > div {
      max-height: 100vh;
      transition: max-height $transition, padding $transition;
      overflow: hidden;
      padding: $table-cell-padding;
    }

    &[hidden] {
      display: table-row !important;
      border-top-color: $table-border-color;

      td {
        @include prevent-double-border(map-deep-get($transitions, slide, duration));

        > div {
          max-height: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
