@import '../../base';

@each $icon-size, $_ in $icon-sizes {
  .icon-size-#{$icon-size} {
    @include icon-size($icon-size);
  }
}

@each $arrow-direction, $_ in $arrow-directions {
  .arrow-direction-#{$arrow-direction} {
    @include arrow-direction($arrow-direction);
  }
}

.icon-settings-master-mint-700 {
  @include icon('settings', $master-mint-700);
}

.icon-settings-white {
  @include icon('settings', $white);
}

.icon-information {
  @include icon('information', $master-mint-700);
}

.icon-search {
  @include icon('search', $master-mint-700);
}

.icon-search-gray {
  @include icon('search', $gray-subtext-accessible);
}

.icon-chevron-right {
  @include icon('chevron-right');
}

.icon-filter-alt-master-mint-700 {
  @include icon('filter-alt', $master-mint-700);
}

.icon-chevron-left-master-mint-800 {
  @include icon('chevron-left', $master-mint-800);
}

.icon-chevron-right-master-mint-700,
.icon-chevron-right {
  @include icon('chevron-right', $master-mint-700);
}

.icon-refresh-master-mint-700 {
  @include icon('refresh', $master-mint-700);
}

.icon-arrow-back {
  @include icon('arrow-back', currentColor);
}

.icon-arrow-forward {
  @include icon('arrow-forward', currentColor);
  width: 22px;
  height: 22px;
  margin-right: 0;
}

.icon-arrow-down {
  @include icon('arrow-drop-down', $primary);
  width: 28px;
  height: 28px;
}

.icon-arrow-up {
  @include icon('arrow-drop-up', $primary);
  width: 28px;
  height: 28px;
}

.icon-edit-pencil {
  @include icon('create', $sure-sage-700);
  cursor: pointer;
}

.icon-add {
  @include icon('add', $master-mint-700);
  cursor: pointer;
}

.icon-delete-row {
  @include icon('remove-circle-outline', $master-mint-700);
  cursor: pointer;
}

.icon-chevron-down {
  @include icon('chevron-down', $master-mint-700);
}

.icon-chevron-up {
  @include icon('chevron-up', $master-mint-700);
}

.icon-save-alt-master-mint-700 {
  @include icon('save-alt', $primary);
}

.caret-down {
  @include icon('caret-down', $master-mint-700);
  display: inline-block;
  margin-left: 4px;
}

.caret-up {
  @include icon('caret-up', $master-mint-700);
  display: inline-block;
  margin-left: 4px;
}

.caret-up-arrow {
  @include icon('caret-up', currentColor);
  display: inline-block;
  width: 12px;
  height: 6px;
  margin-left: map-get($spacers, 1);
}

.caret-down-arrow {
  @include icon('caret-down', currentColor);
  display: inline-block;
  width: 12px;
  height: 6px;
  margin-left: map-get($spacers, 1);
}

.icon-in-progress {
  @include icon('in-progress', $master-mint-700);
  display: inline-block;
  margin-right: 4px;
}

.icon-warning-amber-error-500 {
  @include icon('warning-amber', $error-500);
}

.icon-rotate-left-master-mint-700 {
  @include icon('rotate-left', $primary);
}

.icon-check-circle-outline-success-500 {
  @include icon('check-circle-outline', $success-500);
}

.icon-error-outline-warning-500 {
  @include icon('error-outline', $warning-500);
}

.icon-favorite {
  @include icon('favorite', $primary);
}

.icon-favorite-enabled {
  @include icon('favorite-enabled', $primary);
}

.icon-close {
  @include icon('close');
}

.icon-delete {
  @include icon('icon-delete', $primary);

  &:hover {
    @include icon('icon-delete', $gray-body);
  }

  &:focus-visible {
    @include icon('icon-delete', $black);
  }
}

.icon-arrow-up {
  @include icon('arrow-down', $sure-sage-600);
}

.icon-arrow-down {
  @include icon('arrow-up', $sure-sage-600);
}

.icon-reset {
  @include icon('reset', $sure-sage-700);
}

.icon-check {
  @include icon('check', $sure-sage-700);
}
