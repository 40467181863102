/* You can add global styles to this file, and also import other style files */

@use '@swimlane/ngx-datatable/index.css';
@use '@swimlane/ngx-datatable/themes/material.css';
@use '@swimlane/ngx-datatable/assets/icons.css';
@import 'assets/scss/ingenico-app-datatable';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';

@import '_ds-styles.scss';

$poster-maps: (
  '100': #00ccb3,
  '200': #ffc919,
  '300': #c39dff,
  '400': #ff7499,
  '500': #64c143,
);

* {
  font-family: Inter, sans-serif;
}

/* Splash Screen */
.splash {
  background: linear-gradient(90deg, $master-mint-800, $master-mint-500);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 835px;
    height: 187px;
  }
}

/* Splash Screen */

.cdk-overlay-container {
  z-index: 1030;
}

.content-card {
  padding-top: 24px !important;
}

//overriding apexcharts inheriting weird font faces

.apexcharts-text tspan {
  font-family: 'Inter' !important;
}

.apexcharts-legend-series .apexcharts-legend-marker {
  display: none;
}

/* ng-date-pick customize color */

.owl-dt-container-buttons.owl-dt-container-row {
  .owl-dt-control.owl-dt-control-button.owl-dt-container-control-button:last-child {
    .owl-dt-control-content.owl-dt-control-button-content {
      background-color: $primary;
      color: #ffffff;
    }
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $primary;
}

.owl-dt-container-buttons {
  color: $primary;
}

.owl-dt-container-info .owl-dt-container-info-active {
  color: $primary;
}

.owl-dt-timer-hour12 {
  color: $primary;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
  color: $primary;
}

/* ng-date-pick customize color */

/* Common styles for Modal */

.white-text {
  color: $white !important;
}

.modal-header {
  display: flex;
  align-items: center;
}

.modal-body {
  .row {
    margin-bottom: 1rem;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between !important;
}

// ng-empty
// .ngx-datatable.material .empty-row {
//   height: auto;
//   background-color: #FAFAFA;
//   display: flex;
//   justify-content: center;
//   .no-results-found {
//     width: auto;
//   }
// }

iframe {
  border: none;
}

/* Custom Icon Color */
//custom icon color can be implemented with just @include icon(name, color)
// @include custom-icon-color('selected', #00BFDC);

/* Custom Icon Color */

// /* TEMP */
// .col-side-nav {
//   @media screen and (max-width: map-get($container-max-widths, lg)){
//     @include make-col(4);
//     position: fixed;
//     top: 0;
//     left: 0;
//     height: 100vh;
//     background-color: #2869AC;
//   }
// }
// .col-main {
//   @media screen and (max-width: map-get($container-max-widths, lg)){
//     @include make-col(12);
//     @include make-col-offset(0);
//   }
// }
// /* TEMP */

/* APEXCHARTS styles */

.apexcharts-toolbar {
  z-index: 0 !important;
}

.apexcharts-tooltip {
  padding: 4px;
}

/* card layered */

div.card.card-layered {
  @each $var, $color in $poster-maps {
    &.card-layered-#{$var} {
      @include custom-card-layered-varient($color);
    }
  }
}

/* card layered */

.col-side-nav {
  display: flex;
  flex-direction: column;
  text-align: left !important;

  .side-menu-footer {
    padding: 0 8px;
    text-align: right;
    cursor: pointer;

    .nav-expanded {
      transform: rotateY(180deg);
      transition: all 0.5s ease-in-out;
    }
  }
}

// Financials Operational Details Table
.financial-report {
  table {
    tbody {
      tr:nth-of-type(odd) {
        * {
          background-color: #fcfcfc;
        }
      }

      tr:nth-of-type(even) {
        * {
          background-color: $white;
        }
      }
    }
  }
}

// custom design for Dashboards
.dashboard-card {
  background-color: $sure-sage-500;
  color: $white !important;
  border-radius: $border-radius-md;

  & * {
    color: $white !important;
  }

  h6 {
    padding: 4px 0px 0px 16px;
  }

  .h4 {
    display: list-item;
    list-style-type: none;
    padding: 0px 16px 4px 0px;
  }

  &.clickable:hover {
    background-color: $master-mint-500;
    border-color: transparent;
    cursor: pointer;
  }
}

.subtitle {
  color: $app__header--color;
}

.page-title {
  font-weight: $font-regular;
  color: $app__header--color;
}

.pe-none {
  pointer-events: none;
}

.modal {
  z-index: 1055;
}

.modal-section .modal-content {
  border-radius: $border-radius-md;
  border: 1px solid $gray-border;
  background-clip: inherit;
}

.modal-align-right .modal-content {
  left: 65%;
  height: 650px;
  width: 630px;
}

omnibus-feedback-section.selectable {
  border: solid $border-radius-sm $black-opacity-40;
  border-radius: $border-radius-sm;
}

// date-picker custom CSS

.custom-theme {
  box-shadow: -7px 1px 9px -2px $gray-border;

  .bs-datepicker-head {
    background-color: $gray-row-background;
    border-style: solid;
    border-color: $gray-border;
    border-width: 1px 1px 0px 1px;
    border-radius: map-get($spacers, 1) map-get($spacers, 1) 0 0;

    button {
      color: $icon__fill--color;
      font-weight: $font-medium;
      font-size: $font-body-lg;
    }
  }

  .bs-datepicker-body {
    border-color: $gray-border;

    table {
      th {
        color: $gray-subtext;
      }

      td {
        color: $gray-title;

        span.selected {
          background-color: $icon__hollow--highlight-color;
        }
      }

      &.days span.in-range:not(.select-start):before {
        background: $master-mint-100;
      }
    }
  }

  .bs-media-container {
    bs-days-calendar-view:first-child {
      margin-right: map-get($spacers, 2);
    }
  }
}

.underline {
  text-decoration: underline;
}

.arrow.popover-arrow {
  display: none;
}

//Apex chart css override
.line-chart .apexcharts-zoomin-icon,
.line-chart .apexcharts-zoomout-icon {
  transform: scale(0.85);
}

.line-chart .apexcharts-reset-icon {
  margin-left: 0px;
}

.line-chart .apexcharts-menu-icon,
.line-chart .apexcharts-zoomout-icon,
.line-chart .apexcharts-zoomin-icon,
.line-chart .apexcharts-reset-icon,
.bar-chart .apexcharts-menu-icon,
.tree-map-chart .apexcharts-menu-icon {
  width: 34px;
  height: 34px;
  border-radius: $border-radius-100;
  position: relative;
  bottom: 6px;

  &:hover {
    background-color: $gray-light;

    &:focus-visible {
      border: 1px solid $gray-title;
      background-color: $gray-light;
    }
  }

  .chart-menu {
    width: inherit;
    height: inherit;
    border-radius: $border-radius-100;

    &:focus {
      background: $sure-sage-700;

      .export {
        display: none;
      }
      .export-active {
        display: block;
        top: 3px;
        left: 5px;
        position: relative;
      }
    }

    &:focus-visible {
      outline: none;
      border: 1px $gray-title solid;
      background-color: $gray-light;

      &:hover {
        .tooltip-text {
          visibility: hidden;
          color: $text-medium-gray; /* Reset color */
        }
      }
    }

    &:hover:not(:focus-visible, :focus) {
      position: relative;
      .tooltip-text {
        visibility: visible;
        color: $text-medium-gray;
      }
    }

    .export {
      top: 2px;
      position: relative;
    }

    .export-active {
      display: none;
    }
  }
  div {
    outline: none;
    img {
      position: relative;
      top: 2px;
    }
  }
}

.tree-map-chart .apexcharts-toolbar {
  padding: 0px;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  height: 32px;
  top: 34px;
  left: -50px;
  font-size: $body-1-font-size;
  font-weight: $font-medium;
  background-color: $gray-title;
  padding: map-get($spacers, 1) map-get($spacers, 2);
  line-height: $h6-line-height;
  border-radius: $border-radius-sm;
  white-space: nowrap;
}
.apexcharts-menu.apexcharts-menu-open {
  z-index: -1;
}

a {
  color: $sure-sage-800;
  text-underline-offset: map-get($spacers, 1);
}
