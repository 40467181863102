@import '../../base';
@import 'spacing-scale';

.btn-sm {
  @extend .font-sm;
  font-weight: 500;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 11px 10px 11px 10px;
  height: 38px;
  font-size: $h7-font-size;
}
.btn-md {
  @extend .body-1;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  align-items: center;
  padding: 0px 24px 0px;
}
.btn-lg {
  @extend .h7;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 9px map-get($spacers, 3);
  height: 42px;
}

.btn-primary {
  @include custom-button-variant(
    (
      'background': $master-mint-700,
      'color': $white,
      'border': transparent,

      'hover-background': $master-mint-500-opacity-50,
      'hover-color': $sure-sage-900,
      'hover-border': transparent,
      'hover-text-color': $master-mint-900,

      'focus-background': $sure-sage-900,
      'focus-color': $white,
      'focus-border': transparent,

      'active-background': $master-mint-900,
      'active-color': $white,
      'active-border': transparent,

      'disabled-background': $gray-border,
      'disabled-color': $gray-body,
      'disabled-border': $gray-subtext,
    )
  );

  &.is-on-dark-background {
    @include custom-button-variant(
      (
        'background': $white,
        'color': $primary,
        'border': transparent,

        'hover-background': $white,
        'hover-color': lighten($primary, 12%),
        'hover-border': transparent,

        'active-background': $white,
        'active-color': darken($primary, 8%),
        'active-border': transparent,

        'disabled-background': transparentize($white, 0.5),
        'disabled-color': transparentize($primary, 0.5),
        'disabled-border': transparent,
      )
    );
  }
}

.btn-secondary {
  @include custom-button-variant(
    (
      'background': $white,
      'color': $sure-sage-700,
      'border': $sure-sage-800,

      'hover-background': $master-mint-500-opacity-30,
      'hover-color': $sure-sage-800,
      'hover-border': $sure-sage-900,

      'focus-background': $master-mint-500-opacity-30,
      'focus-color': $master-mint-800,
      'focus-border': $sure-sage-900,

      'active-background': $master-mint-500-opacity-50,
      'active-color': $sure-sage-900,
      'active-border': $sure-sage-900,

      'disabled-background': transparent,
      'disabled-color': $gray-subtext-accessible,
      'disabled-border': $gray-subtext,
    )
  );

  &.is-on-dark-background {
    @include custom-button-variant(
      (
        'background': transparent,
        'color': $white,
        'border': transparentize($white, 0.5),
        'hover-background': transparent,
        'hover-color': $white,
        'hover-border': $white,

        'active-background': transparent,
        'active-color': $white,
        'active-border': $white,

        'disabled-background': transparent,
        'disabled-color': transparentize($white, 0.5),
        'disabled-border': transparentize($white, 0.75),
      )
    );
  }
}

.btn-danger {
  @include custom-button-variant(
    (
      'background': $error-500,
      'color': $white,
      'border': $white,

      'hover-background': $error-600,
      'hover-color': $white,
      'hover-border': $white,

      'focus-background': $error-500,
      'focus-color': $white,
      'focus-border': $white,

      'active-background': $error-700,
      'active-color': $white,
      'active-border': $white,

      'disabled-background': $gray-border,
      'disabled-color': $gray-body,
      'disabled-border': $gray-subtext,
    )
  );
}
.btn-tertiary {
  @include custom-button-variant(
    (
      'background': transparent,
      'color': $sure-sage-700,
      'border': transparent,

      'hover-background': transparent,
      'hover-color': $sure-sage-700,
      'hover-border': transparent,

      'focus-background': transparent,
      'focus-color': $sure-sage-700,
      'focus-border': $master-mint-800,

      'active-background': $master-mint-500-opacity-30,
      'active-color': $gray-body,
      'disabled-background': transparent,
      'disabled-color': $gray-subtext-accessible,
      'disabled-border': transparent,
    )
  );

  &.is-on-dark-background {
    @include custom-button-variant(
      (
        'background': transparent,
        'color': $white,
        'border': transparent,

        'hover-background': transparent,
        'hover-color': $white,
        'hover-border': transparent,

        'active-background': transparent,
        'active-color': $white,
        'active-border': transparent,

        'disabled-background': transparent,
        'disabled-color': transparentize($white, 0.5),
        'disabled-border': transparent,
      )
    );
  }
}

// Standardize icon sizing in buttons

.btn-icon {
  @include icon-size(sm);

  .btn-sm & {
    margin-top: -0.5px;
    margin-bottom: -0.5px;
  }

  .btn-lg & {
    @include icon-size(md);
  }
}

// Align icons in only-icon buttons

.btn-only-icon {
  padding-right: map-get($spacers, 2);
  padding-left: map-get($spacers, 2);

  &.btn-sm {
    padding-right: ($spacer * 0.5);
    padding-top: ($spacer * 0.5);
    padding-left: ($spacer * 0.5);
    padding-bottom: ($spacer * 0.5);
  }

  &.btn-lg {
    padding-right: ($spacer * 1);
    padding-top: ($spacer * 1);
    padding-left: ($spacer * 1);
    padding-bottom: ($spacer * 1);
  }
}

// Align icons in icon-and-text buttons

.btn-icon-and-text,
.btn-group-icon-and-text .btn {
  // margin-bottom: 8px;
  white-space: nowrap;
  .btn-icon {
    display: inline-block;
    margin-right: map-get($spacers, 1);
    margin-bottom: -2px;
  }
}

.btn-icon-and-text.btn-sm,
.btn-group-icon-and-text .btn-sm {
  margin-bottom: 8px;
  white-space: nowrap;
  .btn-icon {
    display: inline-block;
    margin-right: map-get($spacers, 1);
    margin-bottom: -4px;
  }
}

.btn-icon-and-text.btn-lg,
.btn-group-icon-and-text .btn-lg {
  .btn-icon {
    margin-bottom: -5px;
  }
}

.dropdown-menu {
  border-radius: 8px;
  box-shadow: $shadow-modal-dropdown;
  border: 1px solid $gray-border;
}

//TODO: need to remove below css class once we use the common button everywhere

.btn-small {
  height: 24px;
  font-size: $font-size-sm;
  padding: 0 map-get($spacers, 4);
  font-weight: $font-medium;
}
