@import "../../base";

// Some CSS additions to support custom line-heights per heading and small text

h1, .h1 { font-size: $h1-font-size; line-height: $h1-line-height; color: #000000; }
h2, .h2 { font-size: $h2-font-size; line-height: $h2-line-height; color: #000000; }
h3, .h3 { font-size: $h3-font-size; line-height: $h3-line-height; color: #000000; }
h4, .h4 { font-size: $h4-font-size; line-height: $h4-line-height; color: #000000; }
h5, .h5 { font-size: $h5-font-size; line-height: $h5-line-height; color: #000000; }
h6, .h6 { font-size: $h6-font-size; line-height: $h6-line-height; color: #000000; }
h7, .h7 { font-size: $h7-font-size; line-height: $h7-line-height; color: #000000; }
body-1, .body-1 { font-size: $font-body-lg; line-height: $body-1-line-height; color: #000000; }
body-2, .body-2 { font-size: $font-body-md; line-height: $body-2-line-height; color: #000000; }
font-sm, .font-sm { font-size: $font-size-sm; line-height: $line-height-sm; color: #000000; }
font-md, .font-md { font-size: $font-size-md; line-height: $line-height-md; color: #000000; }
font-lg, .font-lg { font-size: $font-size-lg; line-height: $line-height-lg; color: #000000; }
font-xl, .font-xl { font-size: $font-size-xl; line-height: $line-height-xl; color: #000000; }
