.skeleton-loading {
  position: relative;
  background: lighten($bold-blueberry-700, 55%);
  width: 100%;
  height: 1em;
  display: flex;

  &[random-length] {
    width: percentage((40 + random(50)) * 0.01) !important;
  }

  &:before {
    content: ' ';
    white-space: pre;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    animation: 3000ms ease-in-out skeleton infinite;
  }
}
@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3;
  }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1;
  }
  28% {
    width: 100%;
    left: auto;
    right: 0;
  }
  51% {
    width: 0%;
    left: auto;
    right: 0;
  }
  58% {
    width: 0%;
    left: auto;
    right: 0;
  }
  82% {
    width: 100%;
    left: auto;
    right: 0;
  }
  83% {
    width: 100%;
    left: 0;
    right: auto;
  }
  96% {
    width: 0%;
    left: 0;
    right: auto;
  }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3;
  }
}
