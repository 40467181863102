@use "sass:math";

@import "sass-rem/rem";
.font-light {
  font-weight: 300 !important;
}
.font-regular {
  font-weight: normal !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semi-bold {
  font-weight: 600 !important;
}
.font-black {
  font-weight: 900 !important;
}

// font weight variables
$font-light: 300;
$font-regular: normal;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-black: 900;

// Custom Type Scale
// ======================================================================

$h1-font-size: 48px;
$h2-font-size: 40px;
$h3-font-size: 36px;
$h4-font-size: 28px;
$h5-font-size: 24px;
$h6-font-size: 18px;
$h7-font-size: 16px;
$body-1-font-size: 16px;
$body-2-font-size: 15px;
$body-small: 14px;

$h1-line-height: 58px;
$h2-line-height: 48px;
$h3-line-height: 44px;
$h4-line-height: 34px;
$h5-line-height: 29px;
$h6-line-height: 24px;
$h7-line-height: 22px;
$body-1-line-height: 19px;
$body-2-line-height: 17px;

$font-size-sm: 12px;
$line-height-sm: 15px;
$font-size-md: $h7-font-size;
$line-height-md: $h7-line-height;
$font-size-lg: $h5-font-size;
$line-height-lg: $h5-line-height;
$font-size-xl: $h2-font-size;
$line-height-xl: $h2-line-height;

// Bootstrap compatibility
// ======================================================================

// Convert pixel line-heights to unitless values to not break Bootstrap calculations
$line-height-sm: math.div($line-height-sm, $font-size-sm);
$line-height-md: math.div($line-height-md, $font-size-md);
$line-height-lg: math.div($line-height-lg, $font-size-lg);
$line-height-xl: math.div($line-height-xl, $font-size-xl);
$h1-line-height: math.div($h1-line-height, $h1-font-size);
$h2-line-height: math.div($h2-line-height, $h2-font-size);
$h3-line-height: math.div($h3-line-height, $h3-font-size);
$h4-line-height: math.div($h4-line-height, $h4-font-size);
$h5-line-height: math.div($h5-line-height, $h5-font-size);
$h6-line-height: math.div($h6-line-height, $h6-font-size);
$h7-line-height: math.div($h7-line-height, $h7-font-size);
$body-1-line-height: math.div($body-1-line-height, $body-1-font-size);
$body-2-line-height: math.div($body-2-line-height, $body-2-font-size);

// Convert pixel font-sizes to rems to not break Bootstrap calculations
$font-size-sm: rem($font-size-sm);
$font-size-md: rem($font-size-md);
$font-size-lg: rem($font-size-lg);
$font-size-xl: rem($font-size-xl);
$h1-font-size: rem($h1-font-size);
$h2-font-size: rem($h2-font-size);
$h3-font-size: rem($h3-font-size);
$h4-font-size: rem($h4-font-size);
$h5-font-size: rem($h5-font-size);
$h6-font-size: rem($h6-font-size);
$h7-font-size: rem($h7-font-size);
$body-1-font-size: rem($body-1-font-size);
$body-2-font-size: rem($body-2-font-size);
