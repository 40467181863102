@import '../../base';

.nav-link {
  color: $nav-link-color;

  &:hover,
  &:focus {
    color: $nav-link-color;
    background-color: $nav-link-active-background;
  }

  &.active {
    background-color: $nav-link-active-background;
  }

  padding: 0.5rem;
}

.nav-tabs {
  font-size: $h5-font-size;
  line-height: $h5-line-height;

  .nav-item {
    margin-right: map-get($spacers, 3);
  }

  .nav-link {
    padding: $nav-tabs-link-padding-y $nav-tabs-link-padding-x;

    &.active {
      color: $nav-tabs-link-active-color;
    }
  }
}

.nav-tabs.is-on-dark-background {
  .nav-link {
    color: $nav-tabs-link-color-on-dark-background;

    &:hover,
    &:focus {
      color: $master-mint-700;
    }

    &.active {
      color: $nav-tabs-link-active-color-on-dark-background;
      border-color: $nav-tabs-link-active-border-color-on-dark-background;
    }

    &.disabled {
      color: $nav-tabs-link-disabled-color-on-dark-background;
    }
  }
}

.breadcrumb {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  padding-left: 0;
  padding-right: 0;
}
