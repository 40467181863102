@import "sass-rem/rem";
@import "../foundation/spacing-scale";

// Inputs and buttons share 1 set of size configurations

$input-btn-focus-width: 0;

$input-btn-padding-y: rem(3px);
$input-btn-padding-x: rem(map-get($spacers, 2));

$input-btn-padding-y-sm: rem(3.5px);
$input-btn-padding-x: rem(map-get($spacers, 2));

$input-btn-padding-y-lg: rem(3px);
$input-btn-padding-x: rem(map-get($spacers, 2));
